export const teamSkilled = [
  {
    position: 'Architects',
    percent: '95',
  },
  {
    position: 'Designers',
    percent: '90',
  },
  {
    position: 'Marketing And Business Development',
    percent: '83',
  },
  {
    position: 'Structural Engineers',
    percent: '90',
  },
];